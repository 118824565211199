import React from 'react';
import { translateWithI18Next } from '@yumbrands/kfc-i18n/lib';
import { ChannelManager } from 'common/manager';
import { AnchorWithLink } from 'atoms/Anchor';
import { Config } from 'common/constants/SharedConstants';
import SEARCH_STORE_CONSTANTS from 'organisms/SearchStore/AU/Constants/SearchStoreConstants';

import { getValueOrDefaultArray, manipulateAlreadySearchedLocations } from 'common/utilities/utils';

export const showLoginLinkForNonLoggedInUsers = (memberText, singInClickhandler, singInLink) => {
  return (
    <div data-id={Config.HEADER_SIGN_IN_ATTR_DATA_ID}>
      {translateWithI18Next(memberText)}
      <span onClick={singInClickhandler}>
        <AnchorWithLink targetType={singInLink.targetType} href={singInLink.linkUrl} className='delivery-signin-link'>
          {translateWithI18Next(singInLink.linkText)}
        </AnchorWithLink>
      </span>
    </div>
  );
};

export const onSearchError = (status, clearSuggestions) => {
  clearSuggestions();
};

/**
 * Mehtod to store search value to locastorage
 * @param {*} value representing user's selection
 */
export const setLocalStorage = value => {
  const { ALREADY_SERACHED_LOCATIONS } = SEARCH_STORE_CONSTANTS;
  manipulateAlreadySearchedLocations(value, ALREADY_SERACHED_LOCATIONS);
};

/**
 * Mehtod to search value from locastorage
 * @param {*} description representing seggestion's description
 */
export const getLocalStorage = description => {
  const { ALREADY_SERACHED_LOCATIONS } = SEARCH_STORE_CONSTANTS;
  const allSeachedLocations = getValueOrDefaultArray(JSON.parse(localStorage.getItem(ALREADY_SERACHED_LOCATIONS)));
  return allSeachedLocations.includes(description);
};

export const getSortedSuggesstion = (suggestions, filterConditions) => {
  const filteredValues = [];
  if (filterConditions) {
    suggestions.forEach(element => {
      const checkForCondition = element?.types?.some(i => filterConditions.includes(i));
      if (checkForCondition) {
        filteredValues.push(element);
      }
    });
    return getSortedSuggestionList(filteredValues);
  } else {
    return getSortedSuggestionList(suggestions);
  }
};

export const getSortedSuggestionList = values =>
  values.sort((x, y) => {
    if (getLocalStorage(y.description)) {
      return 0;
    } else if (y) {
      return -1;
    }
    return 1;
  });

export const checkTenantFunctions = ({
  handleSelectAddressGlobal,
  onUseThisAddressHandlerGlobal,
  onAddressSelectionGlobal,
}) => ({
  handleSelectAddress: handleSelectAddressGlobal,
  onUseThisAddressHandler: onUseThisAddressHandlerGlobal,
  callOnAddressConfirmHandler: onAddressSelectionGlobal,
});

/**
 * method to get the lat and long data when user choose's current location
 */
export const getCurrentLocationGlobal = async () => {
  await ChannelManager.BindLocation();
  return ChannelManager.address && ChannelManager.coordinates;
};

import { AnalyticsService } from '@yumbrands/react-shared/analytics';
import events from '@yumbrands/react-shared/analytics/Register';
import { getDataLayer } from 'common/utilities/utils';
import { VIEW_ITEM } from 'organisms/MenusPLP/MenuPLPConstants/MenuPLPConstants';
import { logEvent } from 'common/utilities/analyticsUtils';
import { ANALYTICS_SHARED_CONSTANTS } from 'common/constants/SharedConstants';
export const UpsizeSeelectAnalytics = async (modifiersObj, addOnType) => {
  await AnalyticsService.registerEvent(
    events.upsizeSelectedPwa?.name,
    {
      '%event%': 'upsizeSelected',
      '%upsizeProduct%': modifiersObj?.name || 'NA',
      '%size%': modifiersObj?.size || 'NA',
      '%upsizeSelectedFrom%': addOnType || 'NA',
    },
    getDataLayer,
  );
};

export const AddRecommendedToCart = async itemsObj => {
  await AnalyticsService.registerEvent(
    events.addToCart?.name,
    {
      '%event%': 'add_to_cart',
      '%item_name%': itemsObj.item_name,
      '%item_id%': itemsObj.item_id,
      '%item_tag%': itemsObj.item_tag,
      '%price%': itemsObj.price,
      '%item_variant%': itemsObj.item_variant,
      '%item_list_name%': itemsObj.item_list_name,
      '%item_list_id%': itemsObj.item_list_id,
      '%index%': itemsObj.index,
      '%quantity%': itemsObj.quantity,
    },
    getDataLayer,
  );
};

export const ViewPdpProduct = async itemObj => {
  await AnalyticsService.registerEvent(
    events.viewItem?.name,
    {
      '%event%': 'view_item',
      '%item_name%': itemObj.item_name,
      '%item_id%': itemObj.item_id,
      '%price%': itemObj.price,
      '%item_category%': itemObj.item_category,
      '%item_list_name%': itemObj.item_list_name,
      '%item_list_id%': itemObj.item_list_id,
      '%index%': itemObj.index,
      '%quantity%': itemObj.quantity,
    },
    getDataLayer,
  );
};

export const ViewPdpRecommendsList = async itemsList => {
  await AnalyticsService.registerEvent(
    events.viewItemList?.name,
    {
      data: {
        event: 'view_item_list',
        ecommerce: {
          // item_list: {
          items: itemsList || [],
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const RecommendProductClick = async productObj => {
  await AnalyticsService.registerEvent(
    events.selectItemPwa?.name,
    {
      '%event%': 'select_item',
      '%item_name%': productObj.item_name,
      '%item_id%': productObj.item_id,
      '%item_category%': productObj.item_category,
      '%item_variant%': productObj.item_variant,
      '%item_tag%': productObj.item_tag,
      '%item_list_name%': productObj.item_list_name,
      '%item_list_id%': productObj.item_list_id,
      '%index%': productObj.index,
      '%quantity%': productObj.quantity,
      '%price%': productObj.price,
    },
    getDataLayer,
  );
};

export const MakeItAComboClick = async () => {
  await AnalyticsService.registerEvent(
    events.makeItACombo?.name,
    {
      '%event%': 'makeItACombo',
    },
    getDataLayer,
  );
};

export const PurchaseOrderInfo = (userType, itemObj) => {
  logEvent(events?.purchaseOrder?.name, {
    data: {
      event: 'purchase',
      userType: userType,
      ecommerce: itemObj || {},
    },
  });
};

export const ViewOfferDetails = async titleObj => {
  await AnalyticsService.registerEvent(
    events.viewOfferDetailsPwa?.name,
    {
      '%event%': 'viewOfferDetails',
      '%offerName%': titleObj.offerTitle,
    },
    getDataLayer,
  );
};

export const SignUpClick = async itemObj => {
  await AnalyticsService.registerEvent(
    events.signUpClick?.name,
    {
      '%event%': 'signUpClick',
      '%orderValue%': itemObj.orderValue,
    },
    getDataLayer,
  );
};

export const PopUpView = async titleObj => {
  await AnalyticsService.registerEvent(
    events?.popupView?.name,
    {
      '%event%': 'popupView',
      '%popupTitle%': titleObj.popupTitle,
    },
    getDataLayer,
  );
};

export const FindAKFC = async eventName => {
  await AnalyticsService.registerEvent(
    events.findAKFC?.name,
    {
      '%event%': eventName,
    },
    getDataLayer,
  );
};
export const logRemoveToCartEvent = (eventFlag, itemsArray) => {
  logEvent(events[eventFlag]?.name, {
    data: {
      event: eventFlag,
      ecommerce: {
        remove: {
          products: itemsArray,
        },
      },
    },
  });
};
export const quantityIncreaseDecreaseFromCart = async (eventFlag, itemsArray) => {
  await AnalyticsService.registerEvent(
    events?.[eventFlag]?.name,
    {
      data: {
        event: eventFlag,
        ecommerce: {
          // [eventFlag]: {
          items: itemsArray,
          // },
        },
      },
    },
    getDataLayer,
  );
};

export const ViewPdpProductDetail = async itemsList => {
  await AnalyticsService.registerEvent(
    events.viewItemList?.name,
    {
      data: {
        event: VIEW_ITEM,
        ecommerce: {
          detail: {
            products: itemsList || [],
          },
        },
      },
    },
    getDataLayer,
  );
};

export const PostOrderSignInClick = async () => {
  await AnalyticsService.registerEvent(
    events.signUpStart?.name,
    {
      data: {
        event: 'signUpStart',
      },
    },
    getDataLayer,
  );
};

export const logAddToCartEvent = (userType, itemsList, totalAmount) => {
  logEvent(events?.add_to_cart?.name, {
    data: {
      event: ANALYTICS_SHARED_CONSTANTS.ADD_TO_CART,
      userType,
      ecommerce: {
        currency: ANALYTICS_SHARED_CONSTANTS.INR,
        value: totalAmount,
        items: itemsList,
      },
    },
  });
};

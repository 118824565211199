import { AnalyticsService } from '@yumbrands/react-shared/analytics';
import {
  AUTH_CLIENT_ID_WEB,
  GOOGLE_MAP_API_URL,
  GOOGLE_SPOT_AUTH_SCOPE,
  GOOGLE_SPOT_CLIENT_ID,
  GOOGLE_SPOT_GRANT_TYPE,
  GTM_KEY,
  GUEST_CLIENT_ID_WEB,
  LOGIN_PATH_NAME,
} from '@yumbrands/react-shared/config/config.constants';
import { translateWithI18Next } from '@yumbrands/kfc-i18n/lib/i18N';
import { AnalyticsProviders } from '@yumbrands/react-shared/analytics/models/Event';
import ConfigManager from '@yumbrands/react-shared/config/config.manager';
import { config } from '@yumbrands/react-shared/config/config.utils';
import { getWebAppVersionCall } from '@yumbrands/react-shared/redux/Actions/AppVersionAction';
import {
  deleteBasketsAction,
  removeAllCouponAction,
  removeAllItemAction,
  removeCouponAction,
} from '@yumbrands/react-shared/redux/Actions/CartAction';
import { setErrorCode } from '@yumbrands/react-shared/redux/Actions/ErrorAction';
import { getTenantConfig, getTenantConfigUpdate } from '@yumbrands/react-shared/redux/Actions/TenantAction';
import PullToRefresh from 'atoms/PullToRefresh';
import {
  CONFIG_MIN_SUPPORTED_BROWSERS,
  DAILY_CART_STATUS_CHECK_FLAG,
  SESSION_STORAGE_KEYS,
  UNSUPPORTED_BROWSER,
  NETWORK_STATUS,
  LOCAL_STORAGE_KEYS,
  TENANT_DELIVERY_ADDRESS_MAPPING,
  FOREIGN_FONTS,
} from 'common/constants/SharedConstants';
import { AUTO_DETECT_CONSTANTS } from 'organisms/SearchStore/Constants/AutoDetectionConstants';
import { BrowserManager, ChannelManager } from 'common/manager';
import { getCmsClient, initCmsClient } from 'common/utilities/cmsClient';
import {
  createBasketData,
  displayToast,
  getAndCheck,
  getDataLayer,
  getDispositionTypeOnAPIFormat,
  getFilteredItems,
  isEmptyEvery,
  isMobileDevice,
  redirectToHomePage,
  isSupportedTenantCode,
  getTranslation,
  emptyFunction,
} from 'common/utilities/utils';
import { selectHeader } from 'context/selectors';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import { isCartExpired } from 'organisms/CartPage/Utils/cartPageUtils';
import { APPLIED } from 'organisms/CouponsLanding/Constants/CouponConstants';
import { getErrorMsg } from 'organisms/CouponsLanding/utils/CouponUtils';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import WelcomeBackContainer from 'organisms/WelcomeBackModal/Container/WelcomeBackContainer';
import WorkerComponent from 'organisms/WorkerComponent/Container/WorkerComponent';
import PropTypes from 'prop-types';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Alert, ALERT_TYPE } from 'atoms/Alert';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/main.scss';
import { UserOrderContext } from '../context/context';
import RouterInfo from './RouterInfo';
import { getAppConfigs } from '@yumbrands/react-shared/redux/Actions/AppConfigsActions';
import { Environments, HIDE_CONSOLE_LOGS } from './Constants';
import SharedApp from '@yumbrands/react-shared';
import AuthManager from '@yumbrands/react-shared/auth/AuthManager';
import { useHistory } from 'react-router-dom';
import { initModulator } from 'common/utilities/GlobalModulatorUtils';
import LoadingBucket from 'atoms/LoadingBucket';

// Dynamically import font faces
const fontCountry = FOREIGN_FONTS[process.env.REACT_APP_TENANT_CODE] || '';
import(`../styles/fonts/_fonts${fontCountry}.scss`);
export const handleRefresh = pullToRefreshEnabled => {
  if (pullToRefreshEnabled) {
    window.location.reload();
    return true;
  }
  return false;
};

const cartCountCheck = (cartCount, cartCountRef, showCartToastInPDP) => {
  const reorderClickValue = JSON.parse(sessionStorage.getItem('reorderClick'));
  const addOnModalCartBtnClick = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.INDULGENT_ADDONS_CART_CLICK));
  if (cartCountRef != null && cartCount > cartCountRef) {
    !addOnModalCartBtnClick && !reorderClickValue && showCartToastInPDP && displayToast(cartCount - cartCountRef);
  }
};

export const hideConsoleLogs = hideLogs => {
  if (process?.env?.NODE_ENV?.toLowerCase() !== Environments.DEV && hideLogs?.trim()?.toLowerCase() === 'yes') {
    console.log = emptyFunction;
    console.error = emptyFunction;
    console.table = emptyFunction;
    console.warn = emptyFunction;
    console.debug = emptyFunction;
  }
};

export const App = ({ isConfigAvailable }) => {
  let userLoginFlag = useRef();
  const cartCountRef = useRef(null);
  const profileReducer = useSelector(data => data.profileReducer);
  const cartReducer = useSelector(data => data.cartReducer);
  const errorReducer = useSelector(data => data.errorReducer);
  const [isMobile, setMobileHeader] = useState(isMobileDevice(window.innerWidth));
  const [isReturningUser, setIsReturningUser] = useState(false);
  const { INITIAL_DISPOSITION_DATA } = START_ORDER_CONSTANTS;
  const { AUTO_DETECTION_BUTTON_FLAG, GRANTED } = AUTO_DETECT_CONSTANTS;
  const { disposition = {} } = useSelector(selectHeader);
  const { userOrderState, userOrderStateDispatch } = useContext(UserOrderContext);
  const {
    analyticsProviders,
    defaultAnalyticsProviders,
    defaultLanguage,
    country: COUNTRY,
  } = useSelector(
    ({
      tenantReducer: {
        basic = {},
        analyticsProviders: { web: analyticsProviders = {} } = {},
        defaultAnalyticsProviders: { web: defaultAnalyticsProviders = {} } = {},
        country,
      } = {},
    }) => ({ ...basic, analyticsProviders, defaultAnalyticsProviders, country }),
  );

  const userParams = useSelector(data => data.appStateReducer.userStatus);
  const appVersionReducerData = useSelector(data => data?.appVersionReducer?.response ?? null);
  const { allowRemoteWipeOut, componentOptions = { web: { pdp: {}, global: {} } } } = useSelector(
    data => data.tenantReducer,
  );
  const {
    web: {
      pdp: { showCartToastInPDP = false },
      coupons,
      global: { pullToRefreshEnabled = false },
    },
  } = componentOptions;
  const { userLocationAccessStatus } = useSelector(data => data.storesReducer);

  const { userLastActiveTime, cartData, isBasketLoading } = useSelector(data => data.cartReducer);
  const { discountLines = [], foodLines = [], id: basketId } = cartData || {};
  const [cartCount, setCartCount] = useState(reduce(cartData?.foodLines, (sum, number) => sum + number?.quantity, 0));
  const { tenantId, addHope, carryBag, deliveryAddressMapping = {} } = useSelector(data => data?.tenantReducer);

  sessionStorage.setItem('userId', profileReducer?.customerId);
  const dispatch = useDispatch();
  const history = useHistory();

  const clearCartItemHandler = () => {
    localStorage.setItem(DAILY_CART_STATUS_CHECK_FLAG, new Date());
    if (discountLines.length > 0 && basketId) {
      let discountLinesId = discountLines.map(discItem => discItem.id);
      dispatch(
        removeAllCouponAction(
          {
            params: {
              basketId,
            },
            tenantId,
            shouldClearCart: true,
          },
          discountLinesId,
        ),
      );
    } else if (basketId) {
      dispatch(
        removeAllItemAction({
          basketId,
          tenantId,
        }),
      );
    }
  };

  useEffect(() => {
    setIsReturningUser(false);
  }, [userParams.localized]);

  /**
   * Method call on window resize
   */
  const windowResized = () => {
    const isMobileView = isMobileDevice(window.innerWidth);
    setMobileHeader(isMobileView);
  };

  /**
   * Use to add window listner for windown size change
   */
  useEffect(() => {
    window.addEventListener('resize', windowResized);
  }, [isMobile]);

  useLayoutEffect(() => {
    if (isConfigAvailable) {
      const gtmId = config(GTM_KEY);
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [isConfigAvailable]);

  /**
   * Load google map API asynchronouslyr
   */
  useEffect(() => {
    if (isConfigAvailable) {
      const googleMapApiUrl = config(GOOGLE_MAP_API_URL);
      if (googleMapApiUrl) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = googleMapApiUrl.replace('MAP_LANG', defaultLanguage?.key ?? 'en-US');
        window.initMap = () => {
          console.log('Google map has been loaded successfully');
        };
        document.head.appendChild(script);
      }
    }
  }, [isConfigAvailable, defaultLanguage?.key]);

  useEffect(() => {
    cartCountRef.current = cartCount;
    let addHopeCarryBag = getAndCheck(addHope, carryBag);
    if (addHopeCarryBag) {
      let filteredFoodLineData = getFilteredItems(foodLines, addHope, carryBag);
      let DisplayedFoodLineData = filteredFoodLineData?.filter(foodItems => foodItems?.item?.isHidden !== true);
      setCartCount(reduce(DisplayedFoodLineData, (sum, item) => sum + item?.quantity, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(foodLines)]);

  useEffect(() => {
    cartCountCheck(cartCount, cartCountRef?.current, showCartToastInPDP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCount]);

  const getAnalyticsInstancesForProviders = providers =>
    providers?.map(p => {
      if (p === AnalyticsProviders?.GTM) {
        return { GTM: getDataLayer };
      }
      // enable EnhancedBraze
      if (p === AnalyticsProviders?.EnhancedBraze) {
        return { EnhancedBraze: getDataLayer };
      }
      return null;
    });

  useEffect(() => {
    if (!isEmpty(analyticsProviders) && !isEmpty(defaultAnalyticsProviders)) {
      const analyticsInstances = getAnalyticsInstancesForProviders(analyticsProviders);
      AnalyticsService.registerAnalyticsProviders(analyticsInstances);
      AnalyticsService.defaultAnalyticsProviders = defaultAnalyticsProviders;
    }
  }, [analyticsProviders, defaultAnalyticsProviders]);

  useEffect(() => {
    localStorage.setItem(TENANT_DELIVERY_ADDRESS_MAPPING, JSON.stringify(deliveryAddressMapping));
  }, [deliveryAddressMapping]);

  useEffect(() => {
    !!Object.keys(disposition).length &&
      userOrderStateDispatch({ type: INITIAL_DISPOSITION_DATA, value: { disposition } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposition]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DEFAULT_LANGUAGE, JSON.stringify(defaultLanguage));
    getTranslation({ defaultLanguage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage, COUNTRY]);

  useEffect(() => {
    if (allowRemoteWipeOut) {
      dispatch(
        getWebAppVersionCall({
          object: getCmsClient(),
          contentFull: {
            content_type: 'appMetaData',
            include: '5',
          },
        }),
      );
    }
  }, [dispatch, allowRemoteWipeOut]);

  useEffect(() => {
    const webAppVersion = localStorage.getItem(LOCAL_STORAGE_KEYS.WEB_APPVERSION);
    if (!webAppVersion && appVersionReducerData?.webAppVersion) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.WEB_APPVERSION, appVersionReducerData?.webAppVersion);
    } else if (appVersionReducerData?.webAppVersion > Number(webAppVersion)) {
      setTimeout(() => {
        window.localStorage.clear();
        window.location.reload();
      }, 2000);
    }
  }, [appVersionReducerData]);

  useEffect(() => {
    userLoginFlag.current = userParams.loggedIn;
  }, [userParams.loggedIn]);

  useEffect(() => {
    if (userParams.localized && userLocationAccessStatus === GRANTED && window?.google?.maps) {
      userOrderStateDispatch({ type: AUTO_DETECTION_BUTTON_FLAG, value: { autoDetectionClicked: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.google]);

  // check for invalidate cart for member user
  useEffect(() => {
    if (userParams.loggedIn && !isBasketLoading && cartData?.id && isCartExpired(userLastActiveTime)) {
      deleteCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOrderState?.dispositionNew, userLastActiveTime, userParams]);

  // Remove invalidated coupons
  useEffect(() => {
    const invalidCoupons = cartReducer?.cartData?.discountLines?.filter(obj => obj?.status?.name !== APPLIED);
    if (!isEmpty(invalidCoupons) && coupons?.showErrorToast) {
      const errorMsg = getErrorMsg(invalidCoupons?.[0]);
      const hideToast = !window?.location?.pathname?.includes('cart');
      errorMsg &&
        hideToast &&
        toast.dark(
          <div data-testid='invalid-coupons-toast' className='img-text-div'>
            <span className='text'>{errorMsg}</span>
          </div>,
          {
            hideProgressBar: true,
            position: toast.POSITION.BOTTOM_CENTER,
          },
        );
      dispatch(
        removeCouponAction({
          basketId: cartReducer?.cartData?.id,
          tenantId,
          couponCode: invalidCoupons?.[0]?.couponCode,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartReducer?.cartData?.discountLines, cartReducer?.cartData?.id, dispatch, tenantId]);

  useEffect(() => {
    // To detect and show generic error toast message only in development
    process?.env?.NODE_ENV === 'development' &&
      errorReducer?.errorCode &&
      toast.dark(
        <div data-testid='generic-error-toast' className='img-text-div'>
          <span className='text'>{`${translateWithI18Next('somethingWentWrong')} (${errorReducer?.errorCode})`}</span>
        </div>,
        {
          hideProgressBar: true,
          position: toast.POSITION.BOTTOM_CENTER,
          onClose: () => dispatch(setErrorCode(undefined)),
        },
      );

    if (errorReducer?.errorCode === NETWORK_STATUS?.UNAUTHORIZED) {
      sessionStorage?.removeItem(LOCAL_STORAGE_KEYS?.ORDER_TRACKING);
      redirectToHomePage({ history });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorReducer?.errorCode]);

  async function deleteCart() {
    let { dispositionNew } = userOrderState ?? {};
    dispositionNew = isEmptyEvery(dispositionNew)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS?.DISPOSITION_NEW))
      : dispositionNew;
    if (userParams.localized) {
      const params = {
        storeInfo: dispositionNew?.store,
        dispositionType: getDispositionTypeOnAPIFormat(dispositionNew?.type),
      };
      let obj = await createBasketData(params.storeInfo, params.dispositionType, profileReducer.customerId);
      tenantId &&
        dispatch(
          deleteBasketsAction({ basketId: cartData.id }, true, {
            data: obj,
            tenantId,
          }),
        );
    }
  }

  const isPullableFn = () => {
    if (userOrderState?.scheduleOrderData?.showScheduleOrderCatering) {
      return false;
    }
    return !userOrderState?.searchStoreData?.showSearchStore;
  };

  if (!BrowserManager.isBrowserSupported) {
    return (
      <Alert type={ALERT_TYPE.danger} className='appNotAvailable'>
        {translateWithI18Next(UNSUPPORTED_BROWSER) ??
          'Unsupported Browser. Please use following browsers to use the app.'}
        <br />
        {JSON.stringify(BrowserManager.minSupportedBrowsers)}
      </Alert>
    );
  }
  const shouldShowWelcomeBackModal = window?.location?.pathname === '/' || window?.location?.pathname === '/menu';
  return (
    <PullToRefresh
      // disable when mapview is rendering
      isPullable={isMobile && isPullableFn()}
      className='pullToRefresh'
      onRefresh={() => handleRefresh(pullToRefreshEnabled)}
      refreshingContent={null}
      pullingContent={null}
      pullDownThreshold={75}
      maxPullDownDistance={75}
    >
      <RouterInfo />

      {shouldShowWelcomeBackModal && (
        <WelcomeBackContainer
          setIsReturningUser={setIsReturningUser}
          isReturningUser={isReturningUser}
          isLocalized={userParams.localized}
        />
      )}

      {isSupportedTenantCode() && (
        <WorkerComponent
          clearCartItemHandler={clearCartItemHandler}
          setIsReturningUser={setIsReturningUser}
          cartCount={cartCount}
          userParams={userParams}
        />
      )}

      {userOrderState?.showBucketLoader && <LoadingBucket />}
    </PullToRefresh>
  );
};

App.propTypes = {
  isConfigAvailable: PropTypes.bool,
};

App.defaultProps = {
  isConfigAvailable: false,
};

const AppWrapper = () => {
  const { SET_FORCERELOAD } = START_ORDER_CONSTANTS;
  const { userOrderStateDispatch } = useContext(UserOrderContext);

  const {
    tenantReducer: {
      tenantId,
      componentOptions: { web: { global: { supportGooglePaySpot = false } = {} } = {} } = {},
    } = {},
    appConfigsReducer: { response: appConfigResponse, clientId } = {},
  } = useSelector(({ tenantReducer, appConfigsReducer }) => ({ tenantReducer, appConfigsReducer })) ?? {};
  const userParams = useSelector(data => data.appStateReducer.userStatus);
  const [isConfigAvailable, setConfigAvailable] = useState(false);
  const [isCmsInitialized, setCmsInitialized] = useState(false);
  const appConfigResponseStr = JSON.stringify(appConfigResponse) ?? '{}';

  const dispatch = useDispatch();

  useEffect(() => {
    const tenantCode = process?.env?.REACT_APP_TENANT_CODE;
    dispatch(getTenantConfig(tenantCode));
    dispatch(getAppConfigs({ tenantCode }));
    initModulator();
  }, [dispatch]);

  useEffect(() => {
    if (tenantId && clientId && !isEmpty(JSON.parse(appConfigResponseStr))) {
      ConfigManager.setEnvConfigs({
        responseStr: appConfigResponseStr,
        tenantId,
        clientId,
      });

      console.log(`AppConfig: isConfigAvailable? ${ConfigManager.isConfigAvailable}`);

      BrowserManager.bind(config(CONFIG_MIN_SUPPORTED_BROWSERS));
      hideConsoleLogs(config(HIDE_CONSOLE_LOGS));
      SharedApp.initialize();
      SharedApp.envVars = {
        authParams: {
          memberClientId: config(AUTH_CLIENT_ID_WEB),
          guestClientId: config(GUEST_CLIENT_ID_WEB),
        },
      };
      const authParams = SharedApp.envVars?.authParams;
      if (supportGooglePaySpot && !isEmpty(authParams)) {
        console.log('Tenant supports google pay spot, setting spot configs');
        SharedApp.envVars.authParams = {
          ...authParams,
          googleClientId: config(GOOGLE_SPOT_CLIENT_ID),
          googleGrantType: config(GOOGLE_SPOT_GRANT_TYPE),
          googleAuthScope: config(GOOGLE_SPOT_AUTH_SCOPE),
        };
      }
      const preventBootstrap =
        window?.location?.pathname?.includes(config(LOGIN_PATH_NAME)) && window?.location?.search?.includes('code');
      !userParams.loggedIn && !preventBootstrap && AuthManager.getInstance().bootstrapAuthentication();

      setConfigAvailable(ConfigManager.isConfigAvailable);
      dispatch(getTenantConfigUpdate({ tenantId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appConfigResponseStr, clientId]);

  useLayoutEffect(() => {
    console.log('isConfigAvailable', isConfigAvailable);
    isConfigAvailable &&
      (async () => {
        const space = await initCmsClient();
        console.log('contentful has been initialized, space: ', space?.name);
        setCmsInitialized(true);
      })();
  }, [isConfigAvailable, userOrderStateDispatch]);

  useEffect(() => {
    ChannelManager.BindUserAgent();
    userOrderStateDispatch({
      type: SET_FORCERELOAD,
      value: { forceReload: true },
    });
  }, [SET_FORCERELOAD, userOrderStateDispatch]);

  return isCmsInitialized ? <App isConfigAvailable={isConfigAvailable} /> : <></>;
};

export default AppWrapper;
